@import "../../variables.scss";

.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  animation: enter 500ms ease-in-out;
  animation-delay: 1s;
  animation-fill-mode: both;
  background-color: white;
  border-top: 1px solid $primary;
  padding: 30px;
  z-index: 25;
  color: $primary;
  font-size: 0.9rem;
  display: flex;
  align-items: center;

  p {
    margin: 0;
    margin-left: 16px;
  }

  &--leaving {
    animation: leaving 300ms ease-in-out;
    animation-fill-mode: both;
  }

  &__cta {
    margin: 8px;
    border: 1px solid black;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;

    &--cancel {
      color: $primary;
      background-color: white;
    }

    &--accept {
      background-color: $primary;
      color: white;
    }
  }
}

@keyframes enter {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes leaving {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}
