@import "../../variables.scss";

.side-drawer {
  height: 100%;
  background-color: rgba($primary, 0.5);
  backdrop-filter: blur(20px);
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 50vw;
  z-index: 200;
  transform: translateX(-100%);
  transition: 300ms ease-in-out;
}

.side-drawer__menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50vh;
  justify-content: space-evenly;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer li {
  margin-bottom: 4%;
}

.side-drawer ul {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  text-align: center;
}

.side-drawer a {
  color: whitesmoke;
  text-decoration: none;
  transition: 300ms;
}

.side-drawer a:hover,
.side-drawer a:active {
  color: white;
  transform: scale(1.05);
}

.header {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;

  h4 {
    color: white;
  }
}

.social {
  color: white;
  display: flex;
  justify-content: space-around;
}
