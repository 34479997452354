@import "../../variables.scss";

.store-hero {
  position: relative;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  transition: 400ms;
  min-height: 60vh;

  &--off {
    opacity: 0.4;
    filter: saturate(0);
    background-color: rgb(65, 64, 64);
  }

  &__text {
    padding-top: 40px;
    flex: 1;
    margin-left: 40px;
    position: relative;
  }

  &__images {
    width: 25%;
    display: flex;
    flex-wrap: wrap;

    animation: slideIn 800ms;
    animation-delay: 0.8s;
    animation-fill-mode: both;

    &--book {
      width: 50%;
      align-self: flex-end;
    }

    &--cd {
      width: 33.33%;
      aspect-ratio: 2/1;
      overflow: hidden;

      img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
      }
    }
  }
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    &__images {
      width: 100%;
      margin-top: 40px;
      align-items: flex-end;
      &--book {
        width: 20%;
      }

      &--cd {
        width: 20%;
        aspect-ratio: 1/1;
      }
    }
    &__cta {
      right: 40px;
    }
  }

  @include heroTitle;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
