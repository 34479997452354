@import "../../variables.scss";
.downloads {
  color: $primary;
  &__item {
    color: white;
    margin-bottom: 8px;
    border-bottom: 1px solid white;

    & td {
      padding: 10px;
    }

    & a {
      margin-left: 16px;
    }
  }
}
