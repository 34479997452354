.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(20px);
  z-index: 100;
  top: 0;
  left: 0;
}
