@import "../../variables.scss";
.webdev-hero {
  position: relative;
  height: 50vh;
  min-height: 60vh;

  background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 1)
    ),
    url("../../assets/webdev.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;

  padding: 40px;
  transition: 400ms;

  &--off {
    opacity: 0.4;
    filter: saturate(0);
    background-color: rgb(65, 64, 64);
  }

  @include heroTitle;

  @media (max-width: 768px) {
    background-image: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 1)
      ),
      url("../../assets/webdev.png");
  }
}
