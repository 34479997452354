@import "../../variables.scss";

.muso-hero {
  position: relative;
  padding-left: 40px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  transition: 400ms;
  min-height: 70vh;

  &__content {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
  }

  &--off {
    opacity: 0.4;
    filter: saturate(0);
    background-color: rgb(65, 64, 64);
  }

  &__text {
    padding-top: 40px;
    width: 100%;
  }

  @include heroTitle;

  @media (max-width: 768px) {
    padding: 0;
    flex-direction: column;

    &__content {
      flex-direction: column;
    }
    .video-container {
      width: 100%;
    }

    &__text {
      padding: 40px;
      width: 100%;
    }

    &__cta {
      left: 16px;
      bottom: 8px;
    }
  }
}

.video-container {
  position: relative;
  overflow: hidden;
  width: 50%;
  aspect-ratio: 16/9;
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}
