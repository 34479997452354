.list {
  font-weight: 300;
  letter-spacing: 0.2rem;
  margin-top: 40px;

  li {
    margin-bottom: 28px;
    font-size: 1.2rem;
  }

  &--animate {
    li {
      animation: render 500ms;
      animation-fill-mode: both;
    }
  }
}

@keyframes render {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
