@import "../../variables.scss";

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7%;
  background-color: $primary;
  color: whitesmoke;
  letter-spacing: 1.2rem;
  font-weight: 300;
}
