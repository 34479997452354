@import "../../variables.scss";

.store {
  &__header {
    padding: 30px;
    h4 {
      color: white;
    }
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  &__heading {
    padding: 30px;
    color: white;
  }

  &__item {
    position: relative;
    width: 19%;
    min-width: 300px;
    margin: 25px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: whitesmoke;
    color: $primary;
    border-radius: 10px;
    box-shadow: 4px 4px 4px rgba(5, 76, 94, 0.2);

    &__download-badge {
      &:after {
        content: "PDF";
        position: absolute;
        right: 8px;
        top: 8px;
        height: 50px;
        width: 50px;
        color: goldenrod;
        border: 3px solid goldenrod;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(1px);
      }
    }

    &__mp3-badge {
      &:after {
        content: "mp3";
        position: absolute;
        right: 8px;
        top: 8px;
        height: 50px;
        width: 50px;
        color: goldenrod;
        border: 3px solid goldenrod;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(1px);
      }
    }

    &__price {
      font-weight: 700;
    }

    &__text {
      padding: 10px 20px;

      &__buttons {
        height: 90px;
        padding: 10px 20px;
      }
    }

    & img {
      max-width: 100%;
      margin: 0 0 8px 0;
      border-bottom: 1px solid #eee;
      box-shadow: 0px 1px 4px #bbb;
    }

    &__icon {
      position: absolute;
      width: 30%;
      top: 8px;
      right: 8px;
      // box-shadow: none !important;
      border-radius: 8px;
    }

    &__button {
      border: none;
      background-color: white;
      color: $primary;
      width: 100%;
      border-radius: 9px;
      padding: 5px 0;

      &--selected {
        color: darkgreen;
        border: none;
        background-color: white;
        border-radius: 10px;
        outline: none !important;
        pointer-events: none;
      }

      &--remove {
        margin-left: 8px;
        color: darkred;
        border: none;
        background-color: white;
        border-radius: 10px;
        outline: none;
      }
    }
  }
}

.checkout {
  position: sticky;
  top: 56px;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 100;
  box-shadow: 0 3px 3px #ddd;
  transition: 300ms;
  overflow: hidden;

  &__inputs {
    display: flex;
    flex-direction: column;

    & input {
      margin: 8px;
      border-radius: 5px;
      padding: 5px;
      border: 1px solid #bbb;
      max-width: 300px;
    }

    &__address {
      // display: flex;
      & input {
        width: 40%;
      }
    }
  }
  @media (max-width: 700px) {
    .checkout {
      &__inputs {
        flex-direction: column;
      }
    }
  }

  &__backdrop {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
  }

  &__paypal {
    margin-top: 16px;
    color: $primary;
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__count {
    margin-right: 16px;
    color: $primary;
  }

  &__button {
    background-color: $primary;
    border: none;
    color: white;
    padding: 15px;
    border-radius: 10px;
    &:disabled {
      opacity: 0.6;
    }

    &--small {
      color: $primary;
      border: none;
      background-color: white;
      border-radius: 10px;
      outline: none !important;
    }
  }
}

.cancel-button {
  color: darkred;
  margin-top: 30px;
}
